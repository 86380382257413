/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

.l-backdrop{
  display:-webkit-box;
  display:flex;
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index:400;
  background-color:rgba(47, 57, 65, .85);
  overflow:auto;
  -webkit-overflow-scrolling:touch;
}

.l-backdrop--center{
  -webkit-box-align:center;
          align-items:center;
  -webkit-box-pack:center;
          justify-content:center;
}

.l-backdrop.is-rtl{
  direction:rtl;
}

.l-backdrop.is-visible{
  -webkit-animation:zd-backdrop-visible .15s ease-in;
          animation:zd-backdrop-visible .15s ease-in;
}

.l-backdrop .c-dialog{
  -webkit-animation-delay:.01s;
          animation-delay:.01s;
  margin:48px;
  border-color:transparent;
  box-shadow:0 20px 28px 0 rgba(47, 57, 65, .35);
  max-height:calc(100vh - 96px);
  overflow:auto;
}

.l-backdrop--center .c-dialog{
  margin:0;
}

.l-backdrop--center .c-dialog,_:-ms-input-placeholder{
  right:50%;
  bottom:50%;
  transform:translate(50%, 50%);
}

@media (max-height:399px){
  .l-backdrop .c-dialog{
    top:24px;
    bottom:auto;
    margin-bottom:24px;
    max-height:none;
  }
}

@media (max-width:699px){
  .l-backdrop .c-dialog{
    left:24px;
  }

  .l-backdrop.is-rtl .c-dialog{
    right:24px;
    left:auto;
  }
}

.c-dialog{
  display:-webkit-box;
  display:flex;
  position:fixed;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
          flex-direction:column;
  margin-bottom:48px;
  border:1px solid #d8dcde;
  border-radius:4px;
  box-sizing:border-box;
  box-shadow:0 20px 28px 0 rgba(4, 68, 77, .15);
  background-color:#fff;
  width:544px;
  min-height:60px;
}

.c-dialog:focus{
  outline:none;
}

.c-dialog--large{
  width:800px;
}

.c-dialog.is-open{
  -webkit-animation-name:zd-dialog-open;
          animation-name:zd-dialog-open;
  -webkit-animation-duration:.3s;
          animation-duration:.3s;
  -webkit-animation-timing-function:ease-in;
          animation-timing-function:ease-in;
}

.c-dialog.is-rtl{
  direction:rtl;
}

.c-dialog__header{
  display:block;
  margin:0;
  border-bottom:1px solid #e9ebed;
  padding:20px 40px;
  line-height:1.42857;
  color:#2f3941;
  font-size:14px;
  font-weight:600;
}

.c-dialog__header--danger{
  position:relative;
  color:#cc3340;
}

.c-dialog__header--danger:before{
  position:absolute;
  top:22px;
  left:16px;
  background:no-repeat
    50% / 16px
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%23cc3340'%3E%3Cg fill='none' stroke='currentColor'%3E%3Ccircle cx='7.5' cy='8.5' r='7'/%3E%3Cpath stroke-linecap='round' d='M7.5 4.5V9'/%3E%3C/g%3E%3Ccircle cx='7.5' cy='12' r='1' fill='currentColor'/%3E%3C/svg%3E");
  width:16px;
  height:16px;
  content:"";
}

.c-dialog.is-rtl .c-dialog__header--danger:before{
  right:16px;
  left:auto;
}

.c-dialog__close{
  display:block;
  position:absolute;
  top:10px;
  right:20px;
  -webkit-transition:background-color .1s ease-in-out;
  transition:background-color .1s ease-in-out;
  border:none;
  border-radius:50%;
  background-color:transparent;
  cursor:pointer;
  width:40px;
  height:40px;
  overflow:hidden;
  text-decoration:none;
  font-size:0;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}

.c-dialog__close::-moz-focus-inner{
  border:0;
}

.c-dialog__close:after,.c-dialog__close:before{
  display:block;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  -webkit-transition:opacity .25s ease-in-out;
  transition:opacity .25s ease-in-out;
  background-repeat:no-repeat;
  background-position:50%;
  background-size:16px;
  content:"";
}

.c-dialog__close:before{
  opacity:1;
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%2368737d'%3E%3Cpath stroke='currentColor' stroke-linecap='round' d='M3 13L13 3m0 10L3 3'/%3E%3C/svg%3E");
}

.c-dialog__close:after{
  opacity:0;
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%232f3941'%3E%3Cpath stroke='currentColor' stroke-linecap='round' d='M3 13L13 3m0 10L3 3'/%3E%3C/svg%3E");
}

.c-dialog__close.is-focused:before,.c-dialog__close.is-hovered:before,.c-dialog__close:hover:before{
  opacity:0;
}

.c-dialog__close.is-focused:after,.c-dialog__close.is-hovered:after,.c-dialog__close:hover:after{
  opacity:1;
}

.c-dialog__close.is-focused,.c-dialog__close:focus{
  outline:none;
  background-color:rgba(104, 115, 125, .15);
}

.c-dialog__close.is-active,.c-dialog__close:active{
  background-color:transparent;
}

.c-dialog.is-rtl .c-dialog__close{
  right:auto;
  left:20px;
}

.c-dialog__body{
  display:block;
  margin:0;
  padding:20px 40px;
  height:100%;
  overflow:auto;
  line-height:1.42857;
  color:#2f3941;
  font-size:14px;
}

.c-dialog__footer{
  display:-webkit-box;
  display:flex;
  flex-shrink:0;
  -webkit-box-align:center;
          align-items:center;
  -webkit-box-pack:end;
          justify-content:flex-end;
  padding:20px 40px 32px;
}

.c-dialog--large .c-dialog__footer{
  border-top:1px solid #e9ebed;
  padding:32px 40px;
}

.c-dialog__footer__item{
  display:-webkit-box;
  display:flex;
  margin-left:20px;
  min-width:0;
}

.c-dialog__footer__item:first-child{
  margin-left:0;
}

.c-dialog.is-rtl .c-dialog__footer__item{
  margin-right:20px;
  margin-left:0;
}

.c-dialog.is-rtl .c-dialog__footer__item:first-child{
  margin-right:0;
}

@-webkit-keyframes zd-backdrop-visible{
  0%{
    opacity:0;
  }

  to{
    opacity:1;
  }
}

@keyframes zd-backdrop-visible{
  0%{
    opacity:0;
  }

  to{
    opacity:1;
  }
}

@-webkit-keyframes zd-dialog-open{
  0%{
    -webkit-transform:scale(0);
            transform:scale(0);
    opacity:0;
  }

  50%{
    -webkit-transform:scale(1.05);
            transform:scale(1.05);
  }

  to{
    opacity:1;
  }
}

@keyframes zd-dialog-open{
  0%{
    -webkit-transform:scale(0);
            transform:scale(0);
    opacity:0;
  }

  50%{
    -webkit-transform:scale(1.05);
            transform:scale(1.05);
  }

  to{
    opacity:1;
  }
}
